import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness6Outlined from "@mui/icons-material/Brightness6Outlined";
import CssBaseline from "@mui/material/CssBaseline";
import Fab from "@mui/material/Fab";

const Theme = (props) => {
   const [mode, setMode] = useState("dark");

   useEffect(() => {
      const savedTheme = typeof window === "undefined" ? "dark" : window.localStorage.getItem("theme") || "light";
      setMode(savedTheme);
   }, []);

   const themeDefault = createTheme({
      palette: {
         mode: mode,
         background: {
            default: mode === "dark" ? "#222222" : "#e7ebf0",
         },
         text: {
            primary: mode === "dark" ? "#ffffff" : "#222222",
         },
      },
      typography: {
         fontFamily: ["Inter", "sans-serif"].join(","),
      },
      components: {
         MuiOutlinedInput: { styleOverrides: { root: { borderRadius: 10 } } },
         MuiButton: { styleOverrides: { root: { borderRadius: 10 } } },
         MuiAlert: { styleOverrides: { root: { borderRadius: 10 } } },
      },
   });

   const toggleColorMode = () => {
      setMode((prevMode) => {
         if (prevMode === "light") {
            window.localStorage.setItem("theme", "dark");
            return "dark";
         } else {
            window.localStorage.setItem("theme", "light");
            return "light";
         }
      });
   };

   return (
      <ThemeProvider theme={themeDefault}>
         <CssBaseline />
         <Fab color="primary" style={{ position: "fixed", bottom: 20, right: 20 }} onClick={toggleColorMode} size="medium">
            <Brightness6Outlined />
         </Fab>
         {props.children}
      </ThemeProvider>
   );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <Theme>
      <App />
   </Theme>
);
