import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function App() {
	const [items, setItems] = useState([]);
	const [itemName, setItemName] = useState("");

	useEffect(() => {
		let items = window.localStorage.getItem("items") || "[]";
		setItems(JSON.parse(items));
	}, []);

	useEffect(() => {
		window.localStorage.setItem("items", JSON.stringify(items));
	}, [items]);

	const AddItem = () => {
		if (itemName === "") return alert("Enter Item Name");
		setItems((v) => [...v, { id: new Date().getTime(), item: itemName, isChecked: false }]);
		setItemName("");
	};

	const handleCheckbox = (id) => {
		let copy = [...items];
		copy.forEach((row) => {
			if (row.id === id) {
				row.isChecked = !row.isChecked;
			}
		});
		setItems(copy);
	};

	const handleRemove = (id) => {
		let newItems = items.filter((v) => v.id !== id);
		setItems(newItems);
	};

	return (
		<div style={{ padding: 20 }}>
			<Container maxWidth="md">
				<Card variant="outlined" style={{ borderRadius: 10 }}>
					<CardHeader title="Todo App" />
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Grid container spacing={1}>
									{items.length === 0 ? <Grid item>No items</Grid> : null}
									{items.map((data, idx) => {
										return (
											<React.Fragment key={idx}>
												<Grid item xl={8} lg={8} md={8} sm={8} xs={8} style={{ wordBreak: "break-all" }}>
													<FormGroup>
														<FormControlLabel control={<Checkbox checked={data.isChecked} onChange={() => handleCheckbox(data.id)} />} label={data.isChecked ? <s>{data.item}</s> : data.item} />
													</FormGroup>
												</Grid>
												<Grid item>
													<Button variant="contained" color="error" size="small" fullWidth onClick={() => handleRemove(data.id)}>
														Remove
													</Button>
												</Grid>
											</React.Fragment>
										);
									})}
								</Grid>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<Grid container spacing={1}>
									<Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
										<TextField placeholder="Item Name" size="small" autoComplete="off" spellCheck="false" fullWidth value={itemName} onChange={(e) => setItemName(e.target.value)} onKeyUp={(e) => (e.key === "Enter" ? AddItem() : null)} />
									</Grid>
									<Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
										<Button variant="contained" color="success" fullWidth onClick={AddItem}>
											Add Item
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
		</div>
	);
}

export default App;
